@use '~@myjobglasses/account-ui/mixins/colors';

.radio-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &--inline {
    flex-flow: row wrap;
    column-gap: 16px;

    .radio-input__label {
      flex-basis: 100%;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    color: colors.$dark-grey;
  }

  &__radio {
    display: flex;
    column-gap: 12px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: colors.$dark-grey;
    cursor: pointer;

    input[type='radio'] {
      display: none;

      &:checked {
        & ~ .radio-input__radio__checkmark {
          background-color: colors.$primary;

          &::before {
            display: block;
            width: 6px;
            height: 6px;
            content: '';
            background-color: white;
            border-radius: 50%;
          }
        }
      }
    }

    &__checkmark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      background-color: colors.$grey;
      border-radius: 50%;
      transition: background-color 200ms ease-in-out;
    }

    &__icon {
      width: 16px;
      height: 16px;
      fill: colors.$dark-grey;
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    color: colors.$status-error;
  }
}
