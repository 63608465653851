@use '~@myjobglasses/account-ui/mixins/colors';

.thank-you-panel {
  &__title {
    font-family: Avango, sans-serif;
    font-size: 48px;
    line-height: 0;
    text-align: center;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 400;
  }

  &__redirection-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 64px;

    p {
      margin: 0;
      font-size: 16px;
      color: colors.$dark-grey;
    }
  }
}
