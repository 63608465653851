@use '~@myjobglasses/account-ui/mixins/colors';

$sidebar-grey-color: rgba(colors.$grey, 0.5);
$completed-dot-size: 10px;

.sidebar-category {
  border: 2px solid $sidebar-grey-color;
  border-radius: 8px;

  &__title {
    display: flex;
    column-gap: 8px;
    align-items: center;
    padding: 8px 16px;
    margin: 0;
    font-size: 16px;
    line-height: 2;
    color: colors.$primary;
    background-color: $sidebar-grey-color;

    svg {
      stroke: colors.$primary;
      stroke-width: 2px;
    }
  }

  &__panels {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 16px 24px;

    &__item {
      display: flex;
      column-gap: 16px;
      align-items: center;
      padding: 8px;
      font-size: 16px;
      color: rgba(colors.$primary, 0.5);
      text-decoration: none;
      transition: color 100ms ease-in-out;

      &::before {
        display: block;
        width: $completed-dot-size;
        height: $completed-dot-size;
        content: '';
        background-color: colors.$status-error;
        border-radius: 50%;
      }

      &:hover,
      &--active {
        color: colors.$primary;
      }

      &--complete::before {
        background-color: colors.$green;
      }
    }
  }
}
