@use '~@myjobglasses/account-ui/mixins/on-device';

.want-to-share-panel {
  &__row {
    display: flex;
    column-gap: 8px;

    @include on-device.on-device('mobile') {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}
