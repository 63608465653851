@use 'sass:selector';
@use '~@myjobglasses/account-ui/mixins/colors';

@mixin input($pointer-cursor, $custom-input) {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  @if $pointer-cursor {
    cursor: pointer;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    color: colors.$dark-grey;

    &.error {
      color: rgba(colors.$status-error, 0.75);
    }
  }

  &__main {
    display: flex;
    column-gap: 12px;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    border: 1px solid colors.$light-grey;
    border-radius: 10px;
    box-shadow: inset 0 0 2px colors.$dark-grey;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;
    transition-property: border-color, background-color;

    &--focused {
      background-color: colors.$light-grey;
      border-color: colors.$grey;
    }

    &__icon {
      stroke: colors.$dark-grey;
      stroke-width: 1.5;
    }

    @if $custom-input {
      @content;
    } @else {
      > input,
      > div {
        flex: 1;
        font-size: 14px;
        color: colors.$primary;
        background-color: transparent;
        border: none;
        outline: none;

        /* Fix for date input alignment on iOS (╯°□°)╯︵ ┻━┻ */
        &[type='date']::-webkit-date-and-time-value {
          text-align: start;
        }

        &::placeholder,
        &.placeholder {
          color: colors.$dark-grey;
        }

        @if $pointer-cursor {
          cursor: pointer;
        }
      }
    }

    &--error {
      background-color: rgba(colors.$status-error, 0.05);
      box-shadow: inset 0 0 2px colors.$status-error;

      > input::placeholder,
      > div.placeholder {
        color: rgba(colors.$status-error, 0.75);
      }
    }

    &--disabled {
      cursor: not-allowed;
      background-color: rgba(colors.$grey, 0.5);

      > div {
        color: colors.$dark-grey;
        cursor: not-allowed;
      }

      > input {
        color: colors.$dark-grey;
        cursor: not-allowed;
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 4px);
    font-size: 12px;
    color: colors.$status-error;
  }
}

@keyframes dropdownSlideInAnimation {
  from {
    z-index: -10;
    opacity: 0;
    transform: scale(0.8) translateY(-25%);
  }

  to {
    z-index: 2;
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.select-input {
  &__main {
    &__icon {
      transition: transform 100ms ease-in-out;

      &--turn {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    padding: 6px;
    overflow-y: auto;
    list-style: none;
    background-color: colors.$white;
    border-radius: 10px;
    box-shadow: 0 0 5px colors.$grey;
    animation: dropdownSlideInAnimation 200ms cubic-bezier(0.8, 0, 0.2, 1.2);

    &__option {
      border-radius: 8px;

      &:hover {
        background-color: rgba(colors.$grey, 0.1);
      }

      &--selected {
        background-color: rgba(colors.$grey, 0.2);
      }

      button {
        display: flex;
        column-gap: 16px;
        align-items: center;
        width: 100%;
        padding: 6px 0 6px 16px;
        font-size: 14px;
        color: colors.$dark-grey;
        text-align: start;
        background-color: transparent;
        border: none;

        svg {
          width: 16px;
          height: 16px;
          visibility: hidden;
          stroke: colors.$dark-grey;
          stroke-width: 2;
        }
      }

      &--selected {
        button > svg {
          visibility: visible;
        }
      }
    }
    &__add-option {
      button {
        display: flex;
        column-gap: 16px;
        align-items: center;
        padding: 6px 0 6px 16px;
        font-size: 14px;
        color: colors.$dark-grey;
        text-align: start;
        background-color: transparent;
        border: none;

        svg {
          width: 16px;
          height: 16px;
          stroke: colors.$dark-grey;
          stroke-width: 2;
        }
      }
    }
  }

  @include input(true, false);

  label {
    display: flex;
    align-items: center;
  }
}
