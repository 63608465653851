@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';
@import '~react-phone-number-input/style.css';

.phone-input {
  @include input(false, true) {
    .PhoneInput {
      flex: 1;
      font-size: 14px;

      .PhoneInputCountry {
        margin: 0 8px 0 4px;

        .PhoneInputCountrySelectArrow {
          display: none;
        }
      }

      input {
        font-size: 14px;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
}
