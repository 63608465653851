@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '~@myjobglasses/account-ui/mixins/typography';

.PasswordStrengthContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.2rem;
  margin-bottom: dimensions.$space-sm;

  .strengthIndicator {
    flex: 1;
    height: 4px;
    background-color: colors.$on-white-38;
    border-radius: 4px;

    &:nth-child(2) {
      margin: 0 dimensions.$space-xs;
    }

    &.green {
      background-color: colors.$status-success;
    }

    &.red {
      background-color: colors.$status-error;
    }
  }

  .strengthText {
    @include typography.caption;

    width: 65px;
    margin: 0 0 0 dimensions.$space-xs;
    color: colors.$on-white-68;

    &.green {
      color: colors.$status-success;
    }

    &.red {
      color: colors.$status-error;
    }
  }
}
