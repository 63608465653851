@use '~@myjobglasses/account-ui/mixins/colors';

.past-educations-panel {
  &__diplomas {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0;
    margin: 0;
    list-style: none;

    &__add-diploma {
      display: flex;
      column-gap: 16px;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 96px;
      font-size: 16px;
      font-weight: 500;
      color: colors.$grey;
      background-color: transparent;
      border: 2px dashed colors.$grey;
      border-radius: 8px;
      transition-timing-function: ease-in-out;
      transition-duration: 200ms;
      transition-property: border-color, color;

      svg {
        stroke: colors.$grey;
        stroke-width: 2px;
        transition: stroke 200ms ease-in-out;
      }

      &:hover {
        color: colors.$dark-grey;
        border-color: colors.$dark-grey;

        svg {
          stroke: colors.$dark-grey;
        }
      }
    }
  }
}
