.current-education-panel {
  &__period {
    display: flex;
    column-gap: 8px;

    & > * {
      flex: 1;
    }
  }
}
