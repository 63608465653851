@use '~@myjobglasses/account-ui/mixins/colors';

.education-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  padding: 16px;
  color: colors.$primary;
  border: 2px dashed colors.$grey;
  border-radius: 8px;
  transition: border-color 200ms ease-in-out;

  &__edit-button {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 32px;
    height: 32px;
    padding: 8px;
    background-color: colors.$white;
    border: none;
    border-radius: 50%;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    transition-property: transform, background-color;

    &:hover {
      background-color: #f0f0f0;
    }

    svg {
      width: 100%;
      height: 100%;
      stroke: colors.$grey;
      stroke-width: 2px;
      transition: stroke 200ms ease-in-out;
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &__diploma {
      font-size: 16px;
      font-weight: 500;
    }

    &__school-name {
      font-size: 14px;
    }
  }

  &__period {
    display: flex;
    column-gap: 16px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: colors.$dark-grey;

    svg {
      width: 16px;
      height: 16px;
      stroke: colors.$grey;
      stroke-width: 2px;
    }
  }

  &:hover {
    border-color: colors.$dark-grey;

    .education-block__edit-button {
      transform: scale(1.2);

      svg {
        stroke: colors.$dark-grey;
      }
    }
  }
}
