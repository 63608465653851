.buttonPeConnect {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  text-decoration: none;
  border: 2px #e2e8f0 solid;
  border-radius: 8px;
  background-color: #fff;

  &:focus {
    opacity: 0.7;
  }

  .text {
    padding: 0 10px;
    color: #1e293b;
  }
}
