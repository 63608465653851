@use '~@myjobglasses/account-ui/mixins/colors';

.checkbox-input {
  position: relative;

  &__checkbox {
    display: flex;
    column-gap: 12px;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    color: colors.$dark-grey;
    cursor: pointer;

    input[type='checkbox'] {
      display: none;

      &:checked {
        & ~ .checkbox-input__checkbox__checkmark {
          background-color: colors.$primary;

          &::before {
            display: block;
            width: 3px;
            height: 6px;
            content: '';
            border: solid white;
            border-width: 0 3px 3px 0;
            border-radius: 1px;
            transform: translateY(-1px) rotate(45deg);
          }
        }
      }
    }

    &__checkmark {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-top: 2px;
      background-color: colors.$grey;
      border-radius: 2px;
      transition: background-color 200ms ease-in-out;
    }

    span > a {
      font-weight: 500;
      color: colors.$primary;
      text-decoration: none;
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    color: colors.$status-error;
  }
}
