@use '~@myjobglasses/account-ui/mixins/colors';

$horizontal-min-position: 2px;

.toggle-button {
  position: relative;
  width: 44px;
  height: 24px;
  background-color: colors.$grey;
  border: none;
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out;

  &__state-icon {
    position: absolute;
    top: 2px;
    left: $horizontal-min-position;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-weight: 600;
    color: colors.$white;

    &--off {
      right: $horizontal-min-position;
      left: initial;
      font-size: 14px;
    }
  }

  &__pin {
    position: absolute;
    top: 2px;
    left: $horizontal-min-position;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: colors.$white;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(colors.$black, 0.1);
    transition: transform 0.2s ease-in-out;
  }

  &--undefined {
    .toggle-button__pin {
      transform: translateX(calc(44px / 2 - 20px / 2 - $horizontal-min-position));
    }
  }

  &--on {
    background-color: colors.$green;

    .toggle-button__pin {
      transform: translateX(calc(44px - 20px - $horizontal-min-position * 2));
    }
  }

  &--off {
    background-color: colors.$status-alert;
  }
}
