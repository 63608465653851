@use '~@myjobglasses/account-ui/mixins/colors';

.contact-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
  padding: 1rem 2rem 2rem;
  margin-top: 2rem;
  text-align: center;
  background-color: colors.$primary;
  border: 1px solid #d1ad47;
  border-radius: 8px;

  h2 {
    font-weight: 400;
    color: colors.$white;
  }
  button {
    &:hover {
      background-color: colors.$white !important;
    }
  }
}
