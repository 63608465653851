@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.range-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  label {
    font-size: 14px;
    font-weight: 400;
    color: colors.$dark-grey;

    &.error {
      color: rgba(colors.$status-error, 0.75);
    }
  }

  &__main {
    display: flex;
    column-gap: 12px;
    align-items: center;
    height: 48px;
    padding: 0 12px;

    &__icon {
      stroke: colors.$dark-grey;
      stroke-width: 1.5;
    }

    &__input {
      flex: 1;
      margin: 0 8px;

      > .rc-slider {
        &-rail {
          background-color: colors.$grey;
        }

        &-track {
          background-color: colors.$primary;
        }

        &-step > span {
          background-color: colors.$grey;
          border-color: colors.$grey;

          &.rc-slider-dot-active {
            background-color: colors.$primary;
            border-color: colors.$primary;
          }
        }

        &-handle {
          background-color: colors.$primary;
          border-color: colors.$primary;
          opacity: 1;

          &:hover {
            border-color: colors.$primary;
          }

          &-dragging {
            border-color: colors.$primary !important;
            box-shadow: 0 0 0 4px rgba(colors.$primary, 0.5) !important;
          }
        }
      }
    }

    &--error {
      background-color: rgba(colors.$status-error, 0.05);
      box-shadow: inset 0 0 2px colors.$status-error;

      > input::placeholder,
      > div.placeholder {
        color: rgba(colors.$status-error, 0.75);
      }
    }

    &--disabled {
      background-color: rgba(colors.$grey, 0.5);

      > input {
        color: colors.$dark-grey;
      }
    }

    &__suffix {
      display: flex;
      font-size: 14px;
      color: colors.$dark-grey;

      &__value {
        width: 24px;
        font-weight: bold;
        color: colors.$primary;
        text-align: center;
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    color: colors.$status-error;
  }
}
