@use '~@myjobglasses/account-ui/mixins/colors';

$square-size: 128px;
$elements-border-radius: 12px;

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, $square-size);
  gap: 16px;

  & > * {
    width: $square-size;
    height: $square-size;
    border-radius: $elements-border-radius;
  }

  button {
    cursor: pointer;
    border: none;
    transition: opactiy 200ms ease-in-out;

    &:hover {
      opacity: 0.75;
    }

    svg {
      width: 32px;
      height: 32px;
      stroke: colors.$dark-grey;
      stroke-width: 2px;
    }
  }
}
