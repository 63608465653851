@use '~@myjobglasses/account-ui/mixins/colors';

.offer-container {
  padding: 3rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flag {
      display: inline-block;
      padding: 0 1.5rem;
      margin: 1rem 0;
      font-size: 12px;
      line-height: 3rem;
      border-radius: 12px;

      &.green {
        color: #248c13;
        background-color: #d8fbd4;
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  .icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 1rem;
    stroke-width: 2px;
    &__check {
      stroke: #248c13;
    }
    &__cross {
      stroke: #d0021b;
    }
  }

  h2 {
    margin-top: 2rem;
    font-size: 14px;
    font-weight: 600;
  }
}
