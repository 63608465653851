@use '~@myjobglasses/account-ui/mixins/buttons';
@use '~@myjobglasses/account-ui/mixins/colors';
@use '../../../../mixins/page';
@use '../../../../mixins/form';

.gouv-school-create-modal {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &__title {
    margin: 0 0 16px;
    font-family: Avango, sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    color: #09193b;
  }
}
