@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '~@myjobglasses/account-ui/mixins/typography';

.mandatoryPasswordSpecsContainer {
  .helper {
    @include typography.caption;

    margin: 0 0 dimensions.$space-xs 0;
    line-height: 1.6rem;
    color: colors.$on-white-68;

    a {
      @include typography.caption;

      color: colors.$on-white-68;
      text-decoration: underline;

      &:hover {
        @include typography.caption;

        color: colors.$primary;
      }
    }
  }

  .specsContainer {
    padding: 0;
    margin: 0;
    list-style-type: none;

    .spec {
      @include typography.caption;

      line-height: 2rem;
      color: colors.$on-white-68;

      &:not(:last-of-type) {
        margin: 0 0 calc(#{dimensions.$space-xs}/ 2) 0;
      }

      &.valid {
        color: colors.$status-success;

        .bullet {
          background-color: colors.$status-success;
        }
      }

      .bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: dimensions.$space-xs;
        background-color: colors.$on-white-24;
        border-radius: 50%;
      }
    }
  }
}
