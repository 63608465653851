@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '~@myjobglasses/account-ui/mixins/buttons';

@mixin form() {
  .field {
    margin: 0 0 dimensions.$space-md 0;
  }

  .globalError {
    margin: 0 0 dimensions.$space-md 0;
    text-align: center;
  }

  .submitButton {
    @include buttons.contained($rounded: true);

    display: block;
    margin: 0 auto;
  }
}
