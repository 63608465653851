@use '~@myjobglasses/account-ui/mixins/colors';

.authentication {
  &__logo {
    width: 100%;
    margin-top: 64px;
  }

  &__forgotten-password {
    margin: 2px 0 0 4px;
    font-size: 10px;
    font-weight: 500;
    color: colors.$dark-grey;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__no-account {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    font-size: 14px;

    &__signup-link {
      margin-left: 4px;
      color: #ff3460;
    }
  }
}
