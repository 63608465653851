@use '~@myjobglasses/account-ui/mixins/colors';

$only-icon-width: 48px;
$icon-button-with-text-width: calc((24px * 2) + 24px);

.secondary-button {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  color: colors.$black;
  background-color: colors.$white;
  border: none;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: rgba(colors.$grey, 0.75);
  }

  &--only-icon {
    width: $only-icon-width;
    padding: 0;

    .secondary-button__icon {
      stroke-width: 2.5;
    }
  }

  &--icon-button-with-text {
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: $only-icon-width;
    overflow-x: hidden;
    color: colors.$grey;
    transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1.2);
    transition-duration: 250ms;
    transition-property: max-width, color;

    &:hover {
      justify-content: center;
      max-width: 250px;
      color: colors.$black;
    }
  }

  &--danger {
    color: colors.$status-error;
    background-color: colors.$status-error;

    &:hover {
      color: colors.$white;
      background-color: colors.$status-error;
    }

    .secondary-button__icon {
      stroke: colors.$white;
    }
  }

  &__icon {
    flex-shrink: 0;
    stroke: colors.$black;
    stroke-width: 1.5;
  }

  &--outlined {
    color: colors.$white;
    background-color: colors.$primary;
    border: 1px solid colors.$white;

    &:not(&--disabled):hover {
      background-color: rgba(colors.$white, 0.1);
    }
  }

  &--smaller {
    height: 38px;
    margin: 16px;
    font-size: 12px;
    font-weight: 600;
  }

}
