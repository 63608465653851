@use '~@myjobglasses/account-ui/mixins/colors';

.gallery-image {
  position: relative;

  &:hover {
    button {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  button {
    position: absolute;

    /* top:hhhhhhhhhhhh; les dernieres touche que Reynald a tapé avant de partir :'( */
    top: -8px;
    right: -8px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: colors.$primary;
    border-radius: 50%;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.75;
    }

    svg {
      stroke: colors.$white;
      stroke-width: 2px;
    }
  }
}
