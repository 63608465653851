@use '~@myjobglasses/account-ui/mixins/colors';
@use '../../../../mixins/page';

.newPasswordView {
  @include page.viewWrapper;

  .newPassword {
    @include page.viewContent;

    .iconHeader {
      background-color: colors.$on-primary-38;

      .icon {
        color: colors.$primary;
      }
    }
  }
}
