@use '~@myjobglasses/account-ui/mixins/reset';

.link {
  @include reset.link;

  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}
