@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/on-device';

.CheckPasswordContainer {
  position: absolute;
  top: calc(50% + 16px);
  left: calc(100% + 16px);
  z-index: 10;
  width: 100%;
  padding: 16px;
  background-color: colors.$white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(colors.$black, 0.25);
  transform: translateY(-50%);

  @include on-device.on-device('mobile') {
    top: initial;
    bottom: 0;
    left: 0;
  }
}
