@use '~@myjobglasses/account-ui/mixins/colors';

.removable-tag {
  position: relative;
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  color: colors.$primary;
  background-color: rgba(colors.$grey, 0.5);
  border: none;
  border-radius: 4px;
  transition: background-color 200ms ease-in-out;

  svg {
    width: 18px;
    height: 18px;
    stroke: colors.$dark-grey;
    stroke-width: 2px;
    transition: stroke 200ms ease-in-out;
  }

  span {
    &::after {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 0;
      height: 2px;
      content: '';
      background-color: rgba(colors.$status-error, 0.5);
      border-radius: 4px;
      transition: width 200ms ease-in-out;
      transform: translateY(-50%);
    }
  }

  &:hover {
    background-color: rgba(colors.$status-error, 0.25);

    span {
      &::after {
        width: calc(80% - 16px);
      }
    }

    svg {
      stroke: colors.$status-error;
    }
  }
}
