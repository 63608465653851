@use '~@myjobglasses/account-ui/mixins/colors';

.strength-hints {
  position: absolute;
  top: 50%;
  left: calc(100% + 16px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: max-content;
  padding: 16px;
  list-style: none;
  background-color: colors.$white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  transform: translateY(-50%);

  &__hint {
    display: flex;
    column-gap: 12px;
    align-items: center;
    font-size: 12px;

    &::before {
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      background-color: colors.$grey;
      border-radius: 50%;
      opacity: 0.5;
      transition: background-color 250ms ease-in-out;
    }

    &--valid::before {
      background-color: colors.$status-success;
    }
  }
}
