@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/on-device';

.profile-completion-layout {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;

  &__wip {
    display: flex;
    column-gap: 8px;
    align-items: center;
    align-self: flex-start;
    padding: 4px 8px;
    font-size: 14px;
    color: colors.$primary;
    background-color: rgba(colors.$status-error, 0.25);
    border-radius: 8px;

    svg {
      width: 16px;
      height: 16px;
      stroke: colors.$primary;
      stroke-width: 2;
    }
  }

  &__header {
    width: 100%;
    color: colors.$primary;
  }

  &__main {
    width: 100%;

    header {
      margin-bottom: 32px;

      h1 {
        margin: 0 0 16px;
        font-family: Avango, sans-serif;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.4;
        color: colors.$primary;
      }

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: colors.$primary;
        white-space: pre-wrap;

        @include on-device.on-device('mobile') {
          font-size: 14px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .row {
        display: flex;
        column-gap: 32px;

        > div {
          flex: 1;
        }
      }

      > button {
        margin-top: 32px;
      }
    }
  }
}
