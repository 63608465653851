.avatar-crop-modal {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;

  &__file-input {
    display: none;
  }

  &__container {
    position: relative;
    width: 320px;
    height: 320px;
  }
}
