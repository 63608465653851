@use '~@myjobglasses/account-ui/mixins/colors';

$layout-grey-color: rgba(colors.$grey, 0.5);

.profile-layout {
  display: flex;
  column-gap: 24px;
  margin-top: 8px;

  main {
    flex: 1;
    border: 2px solid $layout-grey-color;
    border-radius: 8px;

    header {
      h2 {
        display: flex;
        column-gap: 8px;
        align-items: center;
        padding: 8px 16px;
        margin: 0;
        font-size: 16px;
        line-height: 2;
        color: colors.$primary;
        background-color: $layout-grey-color;

        svg {
          margin-bottom: 4px;
          stroke: colors.$primary;
          stroke-width: 2px;
        }
      }

      p {
        padding: 0 16px;
        font-size: 14px;
        color: colors.$primary;
      }
    }

    form {
      padding: 0 16px 16px;

      footer {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 8px;
        justify-content: flex-start;
      }
    }
  }
}
