@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/separator';
@use '~@myjobglasses/account-ui/mixins/on-device';

$small-container-max-width: 540px;
$container-max-width: 1200px;

.layout {
  display: flex;

  &--full-screen-image {
    width: 100%;
    height: 100%;
    background-color: #0c1d37;
    background-image: linear-gradient(to right, rgba(#0c1d37, 1), rgba(#0c1d37, 0.75)),
      url('../../assets/mosaic-background.jpg');
    background-position: center;
    background-size: 200%;

    .layout__main__container{
      max-width: 1080px;
      &__content {
        main {
          display: flex;
          flex-direction: row;
        }
      }
      &__nav {
        &--white {
          color: white;
          button {
            &:hover {
              background-color: colors.$white;
            }
          }
        }
        &__logo {
          filter: brightness(0) invert(1);
        }
      }
      &__separator {
        visibility: hidden;
      }
    }
  }

  @media (max-width: 768px) {
    &--full-screen-image {
      min-width: fit-content;
      overflow-x: hidden;
      .layout__main__container {
        &__content {
          main {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
    }
  }

  &__main {
    flex: 1;

    &__container {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      max-width: $container-max-width;
      min-height: 100vh;
      padding: 16px;
      margin: 0 auto;

      &--with-aside {
        max-width: $small-container-max-width;
      }

      &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;

        &__logo {
          height: 24px;
        }

        &__languages {
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: center;

          > button {
            font-size: 14px;
            background-color: transparent !important;
            border: none;

            @include on-device.on-device('mobile') {
              font-size: 12px;
            }

            &.selected {
              font-weight: bold;
            }
          }
        }
      }

      &__separator {
        width: 100%;
        height: 1px;
        margin: 0 auto;
        background-color: colors.$grey;
      }

      &__content {
        width: 100%;
        margin: 0 auto;

        main {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          form {
            display: flex;
            flex-direction: column;
            row-gap: 24px;

            .row {
              display: flex;
              column-gap: 32px;

              > div {
                flex: 1;
              }
            }

            > button {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  &__aside {
    flex: 1;
    background-color: #0c1d37;
    background-image: linear-gradient(to right, rgba(#0c1d37, 1), rgba(#0c1d37, 0.75)),
      url('../../assets/mosaic-background.jpg');
    background-position: center 25%;
    background-size: 200%;

    @include on-device.on-device('mobile') {
      display: none;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: colors.$primary;

    a.back-link {
      display: flex;
      column-gap: 8px;
      align-items: center;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 600;
      color: colors.$dark-grey;
      text-decoration: none;

      svg {
        stroke: colors.$dark-grey;
        stroke-width: 2;
      }
    }

    h1 {
      margin: 0;
      font-family: Avango, sans-serif;
      font-size: 24px;
      line-height: 1.5;
    }

    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-separator {
    width: 50%;

    @include separator.separator;

    margin: 24px auto;

    @include on-device.on-device('mobile') {
      margin: 16px auto;
    }
  }
}
