@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.dynamic-autocomplete-categorized-select-input__dropdown__category {
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
  color: colors.$primary;

  &:hover {
    background-color: colors.$white !important;
  }
}

.select-input__dropdown__option {
  font-size: 12px;
}

.input--initial-value {
  color: colors.$primary;
}
