@use '~@myjobglasses/account-ui/mixins/buttons';
@use '~@myjobglasses/account-ui/mixins/colors';
@use '../../../../mixins/page';
@use '../../../../mixins/form';

.confirmNewPasswordView {
  @include page.viewWrapper;

  .confirmNewPassword {
    @include page.viewContent;

    .iconHeader {
      background-color: colors.$on-primary-38;

      .icon {
        color: colors.$primary;
      }
    }

    .form {
      @include form.form;
    }
  }
}
