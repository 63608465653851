@use '~@myjobglasses/account-ui/mixins/colors';

.select-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px;
  background-color: transparent;
  border: 1px solid colors.$light-grey;
  border-radius: 10px;
  box-shadow: inset 0 0 2px colors.$dark-grey;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: background-color, border-color, box-shadow;

  &:hover {
    background-color: colors.$light-grey;
    border-color: colors.$grey;
  }

  &__title {
    position: relative;
    z-index: 1;
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: colors.$primary;
    transition: color 200ms ease-in-out;

    &__icon {
      stroke: colors.$primary;
      stroke-width: 2;
      transition: stroke 200ms ease-in-out;
    }
  }

  &__description {
    padding-right: 16px;
    margin: 0;
    font-size: 14px;
    color: colors.$dark-grey;
    text-align: start !important;
    transition: color 200ms ease-in-out;
  }

  &--selected {
    background-color: colors.$primary !important;
    border-color: colors.$primary !important;
    box-shadow: inset 0 0 2px colors.$primary !important;

    .select-block {
      &__title {
        color: colors.$white;

        &__icon {
          stroke: colors.$white;
        }
      }

      &__description {
        color: colors.$light-grey;
      }
    }
  }
}
