@use '~@myjobglasses/account-ui/mixins/colors';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.strength-progress-bar {
  display: flex;
  column-gap: 4px;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
  opacity: 0.5;

  &__icon {
    stroke: colors.$status-success;
    stroke-width: 2px;
    animation: fadeIn 250ms ease-out;

    &--error {
      stroke: colors.$status-error;
    }
  }

  &__progress {
    position: relative;
    width: 56px;
    height: calc(100% - 18px);
    background-color: colors.$grey;
    border-radius: 8px;

    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: colors.$status-error;
      border-radius: 8px;
      transition: width 500ms ease-out;

      &--medium {
        background-color: colors.$status-alert;
      }

      &--strong {
        background-color: colors.$status-success;
      }
    }
  }
}
