@use '~@myjobglasses/account-ui/mixins/colors';

.share-action {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
  padding: 16px;
  color: colors.$primary;
  background-color: #ececec;
  border: none;
  border-radius: 8px;
  stroke: colors.$primary;
  transition: background-color 200ms ease-in-out;

  &__title-group {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  &__title {
    padding-left: 8px;
  }

  &__text {
    font-size: 14px;
    text-align: left;
  }

  &__toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    &__button {
      padding-left: 8px;
    }
  }

  p {
    margin: 0;
  }

  svg {
    stroke-width: 2;
  }
}
