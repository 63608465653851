@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '../../../../../../mixins/form';

.form {
  @include form.form;

  .password {
    margin: 0 0 dimensions.$space-xs 0;
  }
}
