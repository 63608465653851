@use '~@myjobglasses/account-ui/mixins/colors';

.form-section {
  position: relative;
  padding: 3rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  h1 {
    color: colors.$primary;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: colors.$primary;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .hrChallenges {
    &__tags {
      display: flex;
      flex-wrap: wrap;
      .removable-tag {
        margin-bottom: 2px;
        &:hover {
          background-color: rgba(colors.$status-error, 0.25);
          span {
            &::after {
              width: calc(94% - 16px);
            }
          }
        }
      }
    }
  }
}
