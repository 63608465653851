@use '~@myjobglasses/account-ui/mixins/colors';
@use '../../mixins/page';
@use '../../mixins/form';

.companyAlreadyCustomerView {
  @include page.viewWrapper;

  .companyAlreadyCustomer {
    @include page.viewContent;

    .iconHeader {
      background-color: colors.$on-status-alert-68;

      .icon {
        color: colors.$status-alert;
      }
    }
  }
}
