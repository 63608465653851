@use '~@myjobglasses/account-ui/mixins/colors';

.languages-panel {
  &__languages {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__error {
    color: rgba(colors.$status-error, 0.75);
  }
}
