@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/buttons';
@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '../../../mixins/page';
@use '../../../mixins/form';

.form {
  @include form.form;
  .emailSent {
    @include buttons.button;

    display: table;
    padding: 0 dimensions.$space-xs;
    margin: 0 auto;
    color: colors.$status-success;
    border: 1px solid colors.$status-success;
  }
  button {
    width: 100%;
  }
}
