@use '~@myjobglasses/account-ui/mixins/colors';

$path1-start: path(
  'M22.6307 22.9026L36.5159 13.6843C37.182 13.2709 37.9315 14.0357 37.5151 14.6971L28.1681 28.4212C27.9183 28.8139 27.3563 28.8759 27.0232 28.5452L22.4642 24.0188C22.1728 23.688 22.2352 23.1507 22.6307 22.9026Z'
);
$path1-end: path(
  'M20.054 5.72068L21.5004 1.50085C21.6791 0.737504 26.8267 0.737916 27 1.5L28.1766 5.72068C28.2776 6.17501 27.9696 5.99903 27.5002 6.00071L20.4074 6.07441C19.9675 6.04664 19.9497 6.17574 20.054 5.72068Z'
);
$path2-start: path(
  'M24.5868 32.078L10.7017 41.2963C10.0355 41.7097 9.28608 40.945 9.70243 40.2836L19.0494 26.5594C19.2992 26.1667 19.8613 26.1047 20.1943 26.4354L24.7533 30.9619C25.0448 31.2719 24.9823 31.83 24.5868 32.078Z'
);
$path2-end: path(
  'M28.0871 36.3752L24.7872 52.7118C24.6084 53.4751 23.5377 53.4643 23.3645 52.7022L20.2693 36.3885C20.1683 35.9342 20.5219 35.4929 20.9913 35.4912L27.4157 35.4682C27.841 35.4814 28.1914 35.9201 28.0871 36.3752Z'
);
$path3-start: path(
  'M40.0351 16.9912L37.9326 20.0709C39.0775 22.2824 39.7229 24.7627 39.7229 27.4083C39.7229 36.2753 32.4576 43.4887 23.527 43.4887C20.8624 43.4887 18.3227 42.8273 16.0953 41.6905L12.9727 43.7574C16.012 45.7003 19.6342 46.837 23.527 46.837C34.3312 46.837 43.0745 38.1355 43.0745 27.429C43.1161 23.5846 41.9711 20.0089 40.0351 16.9912Z'
);
$path3-end: path(
  'M28.2044 2.50847L28.8953 6.17284C31.2688 6.92705 33.4789 8.22454 35.3496 10.0953C41.6195 16.3651 41.5829 26.6031 35.268 32.918C33.3838 34.8022 31.1203 36.1303 28.7414 36.9016L27.9949 40.5711C31.5178 39.7958 34.883 38.0383 37.6356 35.2857C45.2753 27.6459 45.3048 15.3106 37.7342 7.73996C35.0452 4.99211 31.7072 3.27331 28.2044 2.50847Z'
);
$path4-start: path(
  'M7.35158 27.4287C7.35158 18.5618 14.6168 11.3484 23.5474 11.3484C26.2953 11.3484 28.8767 12.0304 31.1457 13.2292L34.2475 11.1623C31.1666 9.15746 27.5027 8 23.5474 8C12.7433 8 4 16.7016 4 27.4287C4 31.3351 5.16577 34.9729 7.16423 38.0112L9.24596 34.9522C8.03855 32.6993 7.35158 30.1363 7.35158 27.4287Z'
);
$path4-end: path(
  'M12.4738 32.9991C6.20396 26.7292 6.24059 16.4913 12.5555 10.1764C14.4985 8.23333 16.8061 6.89035 19.2583 6.13354L19.9901 2.47874C16.3938 3.23964 12.9847 5.01193 10.1878 7.80874C2.54814 15.4484 2.51868 27.7838 10.1039 35.369C12.8662 38.1313 16.2627 39.8792 19.8243 40.6145L19.1333 36.9795C16.6864 36.2402 14.3884 34.9137 12.4738 32.9991Z'
);

@keyframes path1FirstAnim {
  0% {
    fill: colors.$primary;
    d: $path1-end;
  }

  25% {
    fill: colors.$primary;
    d: $path1-start;
  }

  100% {
    fill: colors.$primary;
    d: $path1-end;
  }
}

@keyframes path2FirstAnim {
  0% {
    fill: colors.$primary;
    d: $path2-end;
  }

  25% {
    fill: colors.$primary;
    d: $path2-start;
  }

  100% {
    fill: colors.$primary;
    d: $path2-end;
  }
}

@keyframes path3FirstAnim {
  0% {
    fill: colors.$primary;
    d: $path3-end;
  }

  25% {
    fill: colors.$primary;
    d: $path3-start;
  }

  100% {
    fill: colors.$primary;
    d: $path3-end;
  }
}

@keyframes path4FirstAnim {
  0% {
    fill: colors.$primary;
    d: $path4-end;
  }

  25% {
    fill: colors.$primary;
    d: $path4-start;
  }

  100% {
    fill: colors.$primary;
    d: $path4-end;
  }
}

.mjg-animated-icon {
  path {
    &#path1,
    &#path2,
    &#path3,
    &#path4 {
      animation-duration: 2s;
      animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1.2);
      animation-fill-mode: forwards;
    }
  }
}
