@use '~@myjobglasses/account-ui/mixins/colors';
@use '../../../../mixins/page';

.requestNewPasswordView {
  @include page.viewWrapper;

  .requestNewPassword {
    @include page.viewContent;

    .iconHeader {
      background-color: colors.$on-primary-38;

      .icon {
        color: colors.$primary;
      }
    }

    .link {
      display: block;
      margin-top: 16px;
      font-size: 14px;
      color: colors.$primary;
      text-align: center;
    }
  }
}
