@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

$avatar-size: 98px;
$small-avatar-size: calc(#{$avatar-size} / 2);
.upload-logo {
  label {
    position: relative;
    bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    color: colors.$dark-grey;
  }
  .upload-logo-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border: 2px dashed colors.$grey;
    border-radius: 8px;
    
    &__viewer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $avatar-size;
      height: $avatar-size;
      padding: 0;
      margin-bottom: 18px;
      text-align: start;
      cursor: pointer;
      background-color: colors.$grey;
      border: none;
      border-radius: 50%;
      box-shadow: 0 2px 5px rgba(colors.$primary, 0.1);
      transition: background-color 100ms ease-in-out;
      
      &__logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      
      &__placeholder-icon {
        width: 22px;
        height: 22px;
        stroke: colors.$primary;
      }
      
      &:hover {
        background-color: colors.$dark-grey;
      }
    }
    
    &__btn {
      font-size: 12px;
      background-color: transparent;
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
    
    &__error {
      margin-top: 5px;
      font-size: 14px;
      color: #ff4d4f;
    }
    
    &__file-input {
      display: none;
    }
  }
}