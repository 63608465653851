.hr-admin-signup-view {
  display: flex; 
  gap: 1rem;
  justify-content: space-between;

  &__form {
    width: 64%; 
  }

  &__sticky-container { 
    position: sticky;
    top: 1rem; 
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 33%; 
  }

  @media (max-width: 768px) {
    flex-direction: column; 
    align-items: center;

    &__sticky-container { 
      position: static;
      width: 100%;
    }
		&__sticky-container__offer {
      width: 100%;
    }
		&__form {
      order: 2;
      width: 100%;
    }
		&__sticky-container__contact {
      order: 3;
			width: 100%;
  }
}}
