@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/on-device';
@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '~@myjobglasses/account-ui/mixins/shadow';
@use '~@myjobglasses/account-ui/mixins/typography';

@mixin viewWrapper($wide: false) {
  @if $wide {
    max-width: 720px;
  } @else {
    max-width: 560px;
  }

  padding: 0 0 dimensions.$space-md 0;
  margin: 0 auto;
}

@mixin viewContent() {
  @include on-device.on-device('tablet') {
    padding: dimensions.$space-lg dimensions.$space-xxl * 2;
  }

  @include on-device.on-device('tablet-landscape') {
    padding: dimensions.$space-lg dimensions.$space-xxl * 2;
  }

  @include on-device.on-device('desktop') {
    padding: dimensions.$space-lg dimensions.$space-xxl * 2;
  }

  .iconHeader {
    width: 64px;
    height: 64px;
    margin: 0 auto dimensions.$space-lg;
    text-align: center;
    border-radius: 50%;

    .icon {
      font-size: 4.8rem;
      line-height: 64px;
      vertical-align: middle;
    }
  }

  .title {
    @include typography.title4;

    margin: 0 0 dimensions.$space-xl 0;
    font-weight: bold;
    line-height: 2.8rem;
    color: colors.$black;
    text-align: center;
  }

  .description {
    @include typography.body1;

    margin: 0 0 dimensions.$space-xl 0;
    color: colors.$black;
  }
}
