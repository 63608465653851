@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.counter-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  label {
    font-size: 14px;
    font-weight: 400;
    color: colors.$dark-grey;
    text-align: center;

    &.error {
      color: rgba(colors.$status-error, 0.75);
    }
  }

  &__main {
    display: flex;
    column-gap: 24px;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 12px;

    &__control {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      stroke: colors.$dark-grey;
      stroke-width: 2;
    }

    &__content {
      width: 24px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    &--error {
      background-color: rgba(colors.$status-error, 0.05);
      box-shadow: inset 0 0 2px colors.$status-error;

      > input::placeholder,
      > div.placeholder {
        color: rgba(colors.$status-error, 0.75);
      }
    }

    &--disabled {
      background-color: rgba(colors.$grey, 0.5);

      > input {
        color: colors.$dark-grey;
      }
    }

    &__suffix {
      display: flex;
      font-size: 14px;
      color: colors.$dark-grey;

      &__value {
        width: 24px;
        font-weight: bold;
        color: colors.$primary;
        text-align: center;
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    color: colors.$status-error;
  }
}
