@use '~@myjobglasses/account-ui/mixins/colors';

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(colors.$primary, 0.5);
  }

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 11;
    width: min(90%, 720px);
    padding: 16px;
    background-color: colors.$white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(colors.$primary, 0.25);
    transform: translate3d(-50%, -50%, 0);

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid colors.$grey;

      > h2 {
        margin: 0;
        font-family: Avango, sans-serif;
        font-size: 24px;
        line-height: 1;
        color: #09193b;
      }

      &__close-button {
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        transition: background-color 100ms ease-in-out;

        > svg {
          width: 100%;
          height: 100%;
          stroke: colors.$dark-grey;
          stroke-width: 2px;
        }

        &:hover {
          background-color: rgba(colors.$grey, 0.25);
        }
      }
    }

    &__content {
      font-size: 16px;
      color: colors.$dark-grey;
    }
  }
}
