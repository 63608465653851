@use '~@myjobglasses/account-ui/mixins/colors';

.education-modal {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__period {
    display: flex;
    column-gap: 16px;

    & > * {
      flex: 1;
    }
  }

  &__footer {
    justify-content: flex-end;

    &__delete-button {
      background-color: colors.$status-error;
    }
  }
}
