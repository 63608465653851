.sidebar {
  position: sticky;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-width: 480px;

  &__buttons {
    flex-direction: column;
    row-gap: 8px;
    margin-top: 0;
  }
}
