@use '~@myjobglasses/account-ui/mixins/colors';

.header-container {
  padding: 22px;
  margin-bottom: 24px;
  background-color: colors.$primary;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &__title {
    margin: 22px;
    color: colors.$white;
    text-align: center;
  }

  &__subtitle {
    margin: 1rem 8rem;
    h2 {
      font-size: 14px;
      font-weight: 400;
      color: colors.$white;
      text-align: center;
    }
  }
  &__button {
    display: flex;
    justify-content: center;

    margin: 1rem 8rem;
    &--mobile {
      .buttons-group {
        flex-direction: column;
      }
    }
    > button {
      margin-right: 12px;
    }
    
  }
  &__img {
    justify-self: center;
    img {
      height: 256px;
    }
  }
}
