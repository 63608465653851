@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.password-input {
  position: relative;

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 16px;

    &__help-link {
      display: flex;
      column-gap: 4px;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      color: colors.$dark-grey;
      text-decoration: none;

      &,
      svg {
        transition-timing-function: ease-in-out;
        transition-duration: 200ms;
        transition-property: color, stroke;
      }

      &:hover {
        color: colors.$grey;

        svg {
          stroke: colors.$grey;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        stroke: colors.$dark-grey;
        stroke-width: 2px;
      }
    }
  }

  @include input(false, false);

  &__main__show-button {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: rgba(colors.$grey, 0.2);
    }

    svg {
      stroke: colors.$grey;
      stroke-width: 2px;
    }
  }
}
