.buttons-group {
  display: flex;
  column-gap: 8px;
  justify-content: flex-end;
  margin-top: 24px;

  &--fluid {
    > button {
      flex: 1;
    }
  }
}
