@use '~@myjobglasses/account-ui/mixins/colors';

@keyframes pinRotation {
  from {
    transform: rotate(0deg) translateX(-50%);
  }

  to {
    transform: rotate(360deg) translateX(-50%);
  }
}

@keyframes iconAppearance {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.profile-completion-bar {
  display: flex;
  column-gap: 32px;
  align-items: center;
  justify-content: center;
  margin: 24px 0 16px;

  &__progress {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: rgba(colors.$border-color, 0.1);
    border-radius: 10px;

    &__completion {
      height: 100%;
      background-color: colors.$primary;
      border-radius: 10px;
      transition: width 1s ease-in-out;
    }

    &__pin {
      position: absolute;
      bottom: 100%;
      left: 0;
      transition: left 1s ease-in-out;
      transform: translateX(-50%);

      &--animate {
        transform-origin: left;
        animation: pinRotation 1s ease-in-out;
      }

      &__icon {
        position: absolute;
        top: 25%;
        left: 50%;
        opacity: 0;
        stroke: colors.$primary;
        stroke-width: 2;
        transform: translate3d(-50%, -25%, 0);
        animation: iconAppearance 1s cubic-bezier(0.8, 0, 0.2, 1.2) forwards;
        animation-delay: 1s;
      }
    }
  }

  &__percentage {
    width: 48px;
    font-size: 24px;
    font-weight: bold;
    color: colors.$primary;
  }
}
