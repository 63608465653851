@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.google-place-input {
  &__main__icon {
    transition: transform 100ms ease-in-out;

    &--turn {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    width: 100%;
    padding: 8px;
    list-style: none;
    background-color: colors.$white;
    border-radius: 10px;
    box-shadow: 0 0 5px colors.$grey;

    &__option {
      padding: 6px 0 6px 24px;
      border-radius: 6px;

      &:hover,
      &--selected {
        background-color: rgba(colors.$grey, 0.2);
      }

      button {
        width: 100%;
        font-size: 12px;
        text-align: start;
        background-color: transparent;
        border: none;
      }
    }
  }

  @include input(true, false) {
    flex: 1;
    font-size: 14px;
    color: colors.$dark-grey;
    background-color: transparent;
    border: none;
    outline: none;
  }

  label {
    display: flex;
    align-items: center;
  }
}
