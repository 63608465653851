.tarteaucitron-modal-open {
  height: 100%;
  overflow: hidden;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 500 !important;
}

.tarteaucitronLine {
  border-left: 0 solid transparent !important;
}

/****   */

/** BETTER MOBILE MODE **/
@media screen and (width <= 767px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }

  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }

  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    display: inline-block;
    width: 100% !important;
  }

  li.tarteaucitronLine .tarteaucitronName span {
    display: inline-block;
    width: 80% !important;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10% !important;
    padding: 10px 0;
    font-size: 0;
  }

  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group::before {
    font-size: 14px;
    font-weight: 700;
    content: '\0025BE';
  }

  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    .tarteaucitronIsExpanded
    button.tarteaucitron-toggle-group::before {
    content: '\0025B4';
  }
}

@media screen and (width >= 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group::after {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 700;
    content: '\0025BE';
  }

  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    .tarteaucitronIsExpanded
    button.tarteaucitron-toggle-group::after {
    margin-left: 15px;
    content: '\0025B4';
  }
}

/***   */

/***
* Reset CSS
*/
#tarteaucitronRoot div,
#tarteaucitronRoot span,
#tarteaucitronRoot applet,
#tarteaucitronRoot object,
#tarteaucitronRoot iframe,
#tarteaucitronRoot h1,
#tarteaucitronRoot h2,
#tarteaucitronRoot h3,
#tarteaucitronRoot h4,
#tarteaucitronRoot h5,
#tarteaucitronRoot h6,
#tarteaucitronRoot p,
#tarteaucitronRoot blockquote,
#tarteaucitronRoot pre,
#tarteaucitronRoot a,
#tarteaucitronRoot abbr,
#tarteaucitronRoot acronym,
#tarteaucitronRoot address,
#tarteaucitronRoot big,
#tarteaucitronRoot cite,
#tarteaucitronRoot code,
#tarteaucitronRoot del,
#tarteaucitronRoot dfn,
#tarteaucitronRoot em,
#tarteaucitronRoot img,
#tarteaucitronRoot ins,
#tarteaucitronRoot kbd,
#tarteaucitronRoot q,
#tarteaucitronRoot s,
#tarteaucitronRoot samp,
#tarteaucitronRoot small,
#tarteaucitronRoot strike,
#tarteaucitronRoot strong,
#tarteaucitronRoot sub,
#tarteaucitronRoot sup,
#tarteaucitronRoot tt,
#tarteaucitronRoot var,
#tarteaucitronRoot b,
#tarteaucitronRoot u,
#tarteaucitronRoot i,
#tarteaucitronRoot center,
#tarteaucitronRoot dl,
#tarteaucitronRoot dt,
#tarteaucitronRoot dd,
#tarteaucitronRoot ol,
#tarteaucitronRoot ul,
#tarteaucitronRoot li,
#tarteaucitronRoot fieldset,
#tarteaucitronRoot form,
#tarteaucitronRoot label,
#tarteaucitronRoot legend,
#tarteaucitronRoot table,
#tarteaucitronRoot caption,
#tarteaucitronRoot tbody,
#tarteaucitronRoot tfoot,
#tarteaucitronRoot thead,
#tarteaucitronRoot tr,
#tarteaucitronRoot th,
#tarteaucitronRoot td,
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot canvas,
#tarteaucitronRoot details,
#tarteaucitronRoot embed,
#tarteaucitronRoot figure,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot output,
#tarteaucitronRoot ruby,
#tarteaucitronRoot section,
#tarteaucitronRoot summary,
#tarteaucitronRoot time,
#tarteaucitronRoot mark,
#tarteaucitronRoot audio,
#tarteaucitronRoot video {
  padding: 0;
  margin: 0;
  font: inherit;
  font-family: Poppins, sans-serif;
  font-size: 100%;

  /* background: initial; */
  text-align: initial;
  text-shadow: initial;
  vertical-align: baseline;
  border: 0;
}

/* Animation */
#tarteaucitronRoot * {
  transition:
    border 300ms,
    background 300ms,
    opacity 200ms,
    box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot details,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot figure,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol,
#tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote,
#tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote::before,
#tarteaucitronRoot blockquote::after,
#tarteaucitronRoot q::before,
#tarteaucitronRoot q::after {
  content: '';
  content: none;
}

#tarteaucitronRoot table {
  border-spacing: 0;
  border-collapse: collapse;
}

#tarteaucitronRoot a:focus-visible,
#tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
* Better scroll management
*/
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 70px !important;
  border-radius: 10px;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0 solid slategrey;
}

div#tarteaucitronServices {
  background-color: #004680 !important;
  box-shadow: 0 40px 60px #545454;
}

/***
* Responsive layout for the control panel
*/
@media screen and (width <= 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }

  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}

@media screen and (width <= 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
  #tarteaucitron {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
    background: #fff;
    border: 0 !important;
  }

  #tarteaucitron .tarteaucitronBorder {
    padding: 5px;
    border: 0 !important;
  }

  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }

  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}

@media screen and (width >= 768px) and (width <= 991px) {
  #tarteaucitron {
    left: 0 !important;
    width: 90% !important;
    max-height: 80% !important;
    margin: 0 5% !important;
    border: 0 !important;
  }
}

/***
* Common value
*/
#tarteaucitronRoot div#tarteaucitron {
  right: 0;
  left: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  font-size: 11px;
  font-weight: 700;
  color: rgb(66 66 66);
  text-decoration: none;
}

#tarteaucitronRoot button {
  padding: 10px 20px !important;
  font-size: 18px !important;
  background: transparent;
  border: 0;
  border-radius: 30px;
}

#tarteaucitronAlertBig strong,
#tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a,
#tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1,
#tarteaucitron .tarteaucitronH2,
#tarteaucitron .tarteaucitronH3,
#tarteaucitron .tarteaucitronH4,
#tarteaucitron .tarteaucitronH5,
#tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

/***
* Root div added just before </body>
*/
#tarteaucitronRoot {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  line-height: normal;
  color: #333;
  letter-spacing: 1.5px;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  margin: 15px 0 28px;
  font-size: 2.5em;
  color: #fff;
  text-align: center;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
* Control panel
*/
#tarteaucitronBack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483646;
  display: none;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.7;
}

#tarteaucitron {
  position: fixed;
  top: 6%;
  left: 50%;
  z-index: 2147483647;
  display: none;
  width: 860px;
  max-height: 80%;
  padding: 0;
  margin: 0 auto 0 -430px;
}

#tarteaucitron .tarteaucitronBorder {
  height: auto;
  padding: 20px;
  overflow: auto;
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  position: absolute;
  right: 0;
  width: 70px;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: #333;
}

#tarteaucitron #tarteaucitronClosePanel {
  padding-top: 20px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  width: 80%;
  margin: 15px auto 0;
  font-size: 12px;
  color: #555;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  /* background: rgba(51, 51, 51, 0.07); */
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  position: relative;
  display: none;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  box-sizing: border-box;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  display: inline-block;
  width: auto;
  padding: 5px 20px;
  margin: 20px 0 0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  background: #004680;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-weight: 500;
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  position: absolute;
  z-index: 2147483647;
  display: none;
  max-width: 270px;
  padding: 20px;
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgb(51 51 51 / 20%);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  padding: 15px 5px;
  margin: 10px 0;
  overflow: hidden;
  background: rgb(51 51 51 / 10%);
  border-left: 5px solid transparent;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1b870b;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9c1a1a;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  position: relative;
  margin-top: 21px;
  margin-bottom: 0;
  background: #004680;
  border: 3px solid #333;
  border-top: 5px solid #333;
  border-left: 9px solid #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #004680;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-top: 2px;
  margin-left: 15px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0 !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  width: 50%;
  margin-left: 10px;
  text-align: left;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  display: inline-block;
  width: auto;
  padding: 6px 10px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: gray;
  border: 0;
  border-radius: 4px;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1b870b;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9c1a1a;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1b870b;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny:hover {
  background-color: #9c1a1a;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  font-size: 12px;
  color: #333;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  display: inline-block;
  padding: 0 10px;
}

#tarteaucitron .tarteaucitronBorder,
#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain,
#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList,
#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border: none;
  border-color: #004680 !important;
}

/***
* Big alert
*/
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  position: fixed;
  left: 0;
  z-index: 2147483645;
  box-sizing: content-box;
  display: none;
  width: 100%;
  padding: 10px 0;
  margin: auto;
  font-size: 18px !important;
  color: #fff;
  text-align: center;
  background: #004680;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 60%);
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  color: #fff;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  display: inline-block;
  padding: 5px 10px;
  margin-left: 7px;
  font-size: 16px !important;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background: #00aeec;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: #9c1a1a;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog {
  padding: 5px 10px;
  margin-bottom: 3px;
  margin-left: 7px;
  font-size: 13px;
  color: #333;
  background: #fff;
}

#tarteaucitronPercentage {
  position: fixed;
  left: 0;
  z-index: 2147483644;
  width: 0;
  height: 5px;
  background: #0a0 !important;
  box-shadow:
    0 0 2px #fff,
    0 1px 2px #555;
}

/***
* Icon
*/
.tarteaucitronIconBottomRight {
  right: 0;
  bottom: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  position: fixed;
  z-index: 2147483646;
  display: none;
  width: auto;
  background: transparent;
}

#tarteaucitronIcon #tarteaucitronManager {
  display: inline-block;
  padding: 8px 10px;
  font-size: 11px !important;
  color: transparent;
  cursor: pointer;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 24px;
  height: 24px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  display: inline-block;
  color: white;
  content: '\2717';
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  display: inline-block;
  color: white;
  content: '\2713';
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  display: inline-block;
  color: white;
  content: '\271b';
}

/***
* Small alert
*/

.tarteaucitronAlertSmallTop,
.tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  position: fixed;
  right: 0;
  z-index: 2147483646;
  display: none;
  width: auto;
  padding: 0;
  text-align: center;
  background: #004680;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  display: inline-block;
  padding: 8px 10px;
  font-size: 11px !important;
  color: #fff;
  cursor: pointer;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgb(255 255 255 / 5%);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  display: block;
  width: 100%;
  height: 8px;
  margin-top: 5px;
  margin-bottom: 1px;
  overflow: hidden;
  background-color: gray;
  border-radius: 5px;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  width: 0%;
  height: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1b870b;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #fbda26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9c1a1a;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  display: inline-block;
  padding: 0 10px;
  font-size: 30px;
  color: #fff;
  vertical-align: top;
  cursor: pointer;
  background: rgb(255 255 255 / 20%);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgb(255 255 255 / 30%);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  position: fixed;
  right: 0;
  display: none;
  width: 100%;
  max-width: 500px;
  max-height: 70%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  height: auto;
  overflow: auto;
  font-size: 11px;
  color: #333;
  text-align: left;
  background: #fff;
  border: 2px solid #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  padding: 13px 0 9px 13px;
  margin-top: 21px;
  text-align: left;
  background: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  font-size: 16px;
  color: #fff;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  padding: 7px 5px 10px;
  word-wrap: break-word;
  background: rgb(51 51 51 / 10%);
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain:hover {
  background: rgb(51 51 51 / 20%);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListLeft
  a
  strong {
  color: darkred;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListRight {
  display: inline-block;
  width: 30%;
  margin-left: 10%;
  font-size: 11px;
  color: #333;
  vertical-align: top;
}

/***
* Fallback activate link
*/
.tac_activate {
  display: table;
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 12px;
  line-height: initial;
  color: #fff;
  text-align: center;
  background: #333;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  display: inline-block;
  background-color: #1b870b;
}

/***
* CSS for services
*/
ins.ferank-publicite,
ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  width: 120px;
  height: 240px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  position: relative !important;
  display: block !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  text-align: center !important;
  border-color: #555;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

a.tarteaucitronSelfLink {
  position: absolute;
  right: 0;
  bottom: -30px;
  left: 0;
  display: block;
  height: 30px;
  text-align: center !important;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  margin-top: 4px !important;
  font-size: 1.2em !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  top: 10px;
  right: 26px;
  font-size: 1.8rem;
  color: #ffff;
  cursor: pointer;
}

.tarteaucitron-spacer-20 {
  display: block;
  height: 20px;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}
